import React from "react";
import Landing from "./Nav";

const LandingPage = () => {
  return (
    <>
      <>
        <div>
          <div>
            <Landing />
          </div>
        </div>
      </>
    </>
  );
};
export default LandingPage;
