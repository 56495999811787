// import React from "react";
// import { Row, Col } from "react-bootstrap";

const Info = () => {
    // const dirImg = '/assets/img/court/';
    // let dataInfo = [
    //     {img:'1.png', title:'Court A', price:'Rp, 25,000/jm', description:`Badminton A BFB field is a field that has used the international
    //     standard size for Badminton 13.4 x 61 meters. BFB Arena is
    //     located in a very strategic place. This field is equipped with
    //     supporting facilities, such as prayer rooms, air conditioning,
    //     and toilets, canteens. with a carpeted field.`},
    //     {img:'2.jpg', title:'Court B', price:'Rp, 25,000/jm', description:`Badminton A BFB field is a field that has used the international
    //     standard size for Badminton 13.4 x 61 meters. BFB Arena is
    //     located in a very strategic place. This field is equipped with
    //     supporting facilities, such as prayer rooms, air conditioning,
    //     and toilets, canteens. with a carpeted field.`},
    //     {img:'3.png', title:'Court C', price:'Rp, 25,000/jm', description:`Badminton A BFB field is a field that has used the international
    //     standard size for Badminton 13.4 x 61 meters. BFB Arena is
    //     located in a very strategic place. This field is equipped with
    //     supporting facilities, such as prayer rooms, air conditioning,
    //     and toilets, canteens. with a carpeted field.`},
    //     {img:'4.png', title:'Court D', price:'Rp, 25,000/jm', description:`Badminton A BFB field is a field that has used the international
    //     standard size for Badminton 13.4 x 61 meters. BFB Arena is
    //     located in a very strategic place. This field is equipped with
    //     supporting facilities, such as prayer rooms, air conditioning,
    //     and toilets, canteens. with a floored field.`},
    // ];

    // return (
    //     <Row id="infoCourt" className="p-5">
    //         {
    //             dataInfo.map((val, key) => (
    //                 <Col className="col-12 col-lg-6 mb-2" key={key}>
    //                     <div className="card mb-3 shadow" style={{borderRadius:15}}>
    //                         <img src={`${dirImg}${val.img}`} className="card-img-top" alt={val.title} height={200} style={{borderRadius:15}}/>
    //                         <div className="card-body">
    //                             <h5 className="card-title"><b>{val.title} <span className="text-danger text-price">{val.price}</span></b></h5>
    //                             <p className="card-text" style={{fontSize:12}}>{val.description}</p>
    //                         </div>
    //                     </div>
    //                 </Col>
    //             ))
    //         }
    //     </Row>
    // )
}

export default Info;